import { useMemo } from "react";
import { Service } from "./baseService";
import { ENDPOINTS } from "~/constants/apiEndPoints";
import useAuth from "~/hooks/useAuth";
import dynamicAxios from "~/utils/axios";

class ServiceWorker extends Service {
  fetchVariables(variable_name: string) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.globals.variables, {
      params: {
        variable_name,
      },
    });
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceWorker(jwt), [jwt]);
};

export default useService;
