import { useMemo } from "react";
import { SubscriptionDuration } from "@restworld/data-services";
import { JobApplication, Note, Restaurant, Worker, WorkerResearchInfo } from "@restworld/utility-types";
import { RWMember } from "types";
import { Service } from "./baseService";
import { ENDPOINTS } from "~/constants/apiEndPoints";
import useAuth from "~/hooks/useAuth";
import dynamicAxios from "~/utils/axios";

type WorkerUpdated = Worker & { research_info?: WorkerResearchInfo };
export type JobApplicationUpdated = JobApplication & {
  id: string;
  restaurant_id?: string;
  worker: WorkerUpdated;
  cover_letter?: string;
};
export type IndexSpontaneousApplicationsResponse = { application: JobApplicationUpdated; worker_distance: number }[];

export type Organization = {
  id: string;
  name: string;
  assigned_to?: string;
  assigned_to_rw_member?: RWMember;
  created_by?: string;
  created_by_rw_member?: RWMember;
  notes?: Note[];
  // employer_user_contacts?: EmployerUserContact[];
  restaurants?: Restaurant[];
  // companies?: Company[];

  inserted_at: string;
  updated_at: string;
};

class ServiceWorker extends Service {
  showEmployer(employerId: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.employers.rootSlashed}${employerId}`);
  }

  indexRestaurants(limit: number, offset: number, params?: { only_active?: boolean }) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.restaurants, {
      params: {
        limit,
        offset,
        ...params,
      },
    });
  }

  indexJobPositions(limit: number, offset: number) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.jobPositions, {
      params: {
        limit,
        offset,
      },
    });
  }

  indexPublicJobPositions() {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.employers.jobPositions}/active`);
  }

  indexReferralJobPositions() {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.referralJobPositions);
  }

  fetchJPosition(slug?: string[] | null) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.employers.jobPositions}/${slug}`);
  }

  createCheckoutSession(endpoint: string) {
    return dynamicAxios().get(endpoint);
  }

  fetchWorker(worker_id: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workers.rootSlashed}${worker_id}`);
  }

  fetchJobPosition(jp: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.employers.specificJobPosition}${jp}`);
  }

  fetchJobApplication(data: object) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.jobApllication, data);
  }

  updateJobApplication({
    workerId,
    jobPositionId,
    restaurantId,
    statusKey,
    params,
  }: {
    workerId: string;
    jobPositionId?: string;
    restaurantId?: string;
    statusKey?: string;
    params?: Partial<JobApplication>;
  }) {
    return dynamicAxios(this.jwt).patch(ENDPOINTS.workerJobPositions.jobApplication, {
      job_position_id: jobPositionId,
      worker_id: workerId,
      status_key: statusKey,
      restaurant_id: restaurantId,
      ...params,
    });
  }

  fetchActiveJobPosition(id?: string | string[]) {
    return dynamicAxios().get(`${ENDPOINTS.employers.jobPositionActive}${id}`);
  }

  redirectStripeCustomerPortal() {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.stripeCustomerPortal);
  }

  createJobPositionViewLog(jobPositionId: string) {
    return dynamicAxios().post(ENDPOINTS.employers.jobPositionViewLog, {
      job_position_id: jobPositionId,
    });
  }

  createStripeCheckoutSession(invoiceId: number) {
    return dynamicAxios().get<{ url: string }>(ENDPOINTS.employers.createCheckoutSession, {
      params: { invoice_id: invoiceId },
    });
  }

  createSubscriptionSession({
    companyId,
    duration,
    pricingType,
    slots,
  }: {
    companyId: string;
    duration: SubscriptionDuration;
    pricingType: "old" | "new";
    slots: number;
  }) {
    return dynamicAxios().post(ENDPOINTS.employers.subscriptionSession, {
      company_id: companyId,
      duration,
      pricing_type: pricingType,
      slots,
    });
  }

  fetchSpontaneousApplications({ restaurantId, workerId }: { restaurantId?: string; workerId?: string }) {
    return dynamicAxios(this.jwt).get<IndexSpontaneousApplicationsResponse | JobApplication[]>(
      ENDPOINTS.workerJobPositions.spontaneousApplications,
      {
        params: {
          restaurant_id: restaurantId,
          worker_id: workerId,
        },
      }
    );
  }

  fetchSpontaneousApplication({ restaurantId, workerId }: { restaurantId: string; workerId: string }) {
    return dynamicAxios(this.jwt).get<JobApplicationUpdated>(ENDPOINTS.workerJobPositions.spontaneousApplication, {
      params: {
        restaurant_id: restaurantId,
        worker_id: workerId,
      },
    });
  }

  showOrganization(organizationId: string) {
    return dynamicAxios(this.jwt).get<Organization>(`${ENDPOINTS.organizations.root}/${organizationId}`);
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceWorker(jwt), [jwt]);
};

export default useService;
