import { useMemo } from "react";
import { Service } from "./baseService";
import { ENDPOINTS } from "~/constants/apiEndPoints";
import useAuth from "~/hooks/useAuth";
import dynamicAxios from "~/utils/axios";
import { EntityId, JobApplication } from "~/@types/common";

class ServiceWorker extends Service {
  updateJobPosition(data: object) {
    return dynamicAxios(this.jwt).patch(`${ENDPOINTS.workerJobPositions.jobApplication}`, data);
  }

  updateJobApplication(jobPositionId: EntityId, workerId: EntityId, params: Partial<JobApplication>) {
    return dynamicAxios(this.jwt).patch(`${ENDPOINTS.workerJobPositions.jobApplication}`, {
      job_position_id: jobPositionId,
      worker_id: workerId,
      ...params,
    });
  }

  listJobApplications({ jobPositionId, organizationId }: { jobPositionId?: string; organizationId?: string }) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workerJobPositions.jobApplication}s`, {
      params: {
        job_position_id: jobPositionId,
        organization_id: organizationId,
        status_key: [
          "worker_jp_in_shortlist",
          "worker_jp_viewed_by_employer",
          "worker_jp_employer_interested",
          "worker_jp_interview_scheduled",
          "worker_jp_interview_done",
          "worker_jp_stage_scheduled",
          "worker_jp_stage",
          "worker_jp_stage_done",
          "worker_jp_scheduled_hiring",
          "worker_jp_discarded",
        ],
      },
    });
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceWorker(jwt), [jwt]);
};

export default useService;
