import { createContext, ReactNode, useEffect, useReducer, useCallback } from "react";
import { getRequest, postRequest } from "~/utils/api";
import { setSession, setUserType } from "~/utils/jwt";

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
  Reset = "RESET_PASSWORD",
}

export type JWTContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser | UserData;
  isReady: boolean;
  jwt?: string;
  logoutRedirect?: boolean;
  method: "jwt";
  login: (jwt: string, userType?: string) => Promise<void>;
  signUp: (jwt: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (jwt: string) => Promise<void>;
};

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  isReady: boolean;
  jwt?: string;
  logoutRedirect?: boolean;
};

export interface UserData {
  id: string;
  email: string;
  name: string;
  surname: string;
  type: string;
  business_name?: string;
  inserted_at: string;
  has_signed_up?: boolean;
  organization_id?: string;
}

export type AuthUser = null | UserData;

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isReady: false,
  user: null,
  logoutRedirect: false,
};

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: AuthUser;
    isReady: boolean;
    jwt?: string;
  };
  [Types.Login]: {
    isAuthenticated: boolean;
    user: AuthUser;
    isReady: boolean;
    jwt: string;
  };
  [Types.Logout]: {
    isAuthenticated: boolean;
    user: AuthUser;
    isReady: boolean;
    jwt: string;
  };
  [Types.Register]: {
    isAuthenticated: boolean;
    user: AuthUser;
    isReady: boolean;
    jwt: string;
  };
  [Types.Reset]: {
    isAuthenticated: boolean;
    isReady: boolean;
    user: AuthUser;
    jwt: string;
  };
};

type indexing = { [index: string]: any };

type ActionMap<M extends indexing> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
        payload: M[Key];
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type JWTActions = ActionMap<indexing>[keyof ActionMap<JWTAuthPayload>];

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        isReady: true,
        jwt: action.payload.jwt,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isReady: true,
        jwt: action.payload.jwt,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isReady: true,
        jwt: null,
        logoutRedirect: true,
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        isReady: true,
        jwt: action.payload.jwt,
      };
    case "RESET_PASSWORD":
      return {
        ...state,
        isAuthenticated: true,
        isReady: true,
        user: action.payload.user,
        jwt: action.payload.jwt,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("jwt");
        const userType = window.localStorage.getItem("userType");
        if (accessToken) {
          const response = await getRequest("/accounts/me", {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          });
          const user = response.data.data;
          if (!!userType) {
            user.type = userType;
          }

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              isReady: true,
              user,
              jwt: accessToken,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              isReady: true,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            isReady: true,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  //login method
  const login = useCallback(async (jwt: string, type?: string) => {
    try {
      const res = await getRequest("/accounts/me", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const user = res.data.data;
      if (!!type) {
        user.type = type;
      }

      setUserType(type);
      setSession(jwt);
      dispatch({
        type: Types.Login,
        payload: {
          isAuthenticated: true,
          user,
          isReady: true,
          jwt,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  //register method
  const signUp = useCallback(async (jwt: string) => {
    try {
      const res = await getRequest("/accounts/me", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const user = res.data.data;
      setSession(jwt);
      dispatch({
        type: Types.Register,
        payload: {
          isAuthenticated: true,
          user,
          isReady: true,
          jwt,
        },
      });
    } catch (error: any) {
      dispatch({
        type: Types.Register,
        payload: {
          isAuthenticated: false,
          user: null,
          isReady: true,
          error,
        },
      });
    }
  }, []);

  //logout method
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.Logout,
      payload: {
        isAuthenticated: false,
        user: null,
        isReady: true,
        jwt: null,
        logoutRedirect: true,
      },
    });
  }, []);

  // reset password method
  const resetPassword = useCallback(async (jwt: string) => {
    const response = await getRequest("/accounts/me", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const user = response.data.data;
    setSession(jwt);
    dispatch({
      type: Types.Login,
      payload: {
        isAuthenticated: true,
        isReady: true,
        user,
        jwt,
      },
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
