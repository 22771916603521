import { useMemo } from "react";
import { Service } from "./baseService";
import { ENDPOINTS } from "~/constants/apiEndPoints";
import useAuth from "~/hooks/useAuth";
import dynamicAxios from "~/utils/axios";

class ServiceWorker extends Service {
  passwordForgot(email?: string) {
    return dynamicAxios().get(ENDPOINTS.accounts.forgotPassword, {
      params: {
        email: email,
      },
    });
  }

  fetchForm(id: string) {
    return dynamicAxios().get(`${ENDPOINTS.accounts.formId}${id}`);
  }

  fetchFormSession(form_id: string) {
    return dynamicAxios().get(ENDPOINTS.accounts.formSessionId, {
      params: {
        form_id,
      },
    });
  }

  login(data: object) {
    return dynamicAxios().post(ENDPOINTS.accounts.login, data);
  }

  resetPassword(id: string, password: string, jwt: string) {
    return dynamicAxios(this.jwt).post(
      ENDPOINTS.accounts.resetPassword,
      {
        id,
        password,
      },
      { headers: { Authorization: `Bearer ${jwt}` } }
    );
  }

  signUp(data: FormData) {
    return dynamicAxios().post(ENDPOINTS.accounts.signUp, data);
  }

  updateWorker(data: object) {
    return dynamicAxios(this.jwt).patch(ENDPOINTS.accounts.rootSlashed, data);
  }

  unsubscribe(userId: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.accounts.rootSlashed}unsubscribe`, { params: { user_id: userId } });
  }

  passwordlessLogin(email: string) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.accounts.passwordlessLogin, { email: email });
  }

  fetchEmployerUserContact(user_id: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.accounts.employerUserContact}/${user_id}`);
  }

  updateEmployerUserContact(params: any, userId?: string) {
    const headers =
      params instanceof FormData
        ? {
            "content-type": "multipart/form-data",
          }
        : {
            "content-type": "application/json",
          };
    return dynamicAxios(this.jwt).patch(`${ENDPOINTS.accounts.employerUserContact}/${userId}`, params, {
      headers,
    });
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceWorker(jwt), [jwt]);
};

export default useService;
