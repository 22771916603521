export function setSession(accessToken: string | null | undefined) {
  if (accessToken) {
    localStorage.setItem("jwt", accessToken);
  } else {
    localStorage.removeItem("jwt");
  }
}

export function setUserType(userType: string | null | undefined) {
  if (userType) {
    localStorage.setItem("userType", userType);
  } else {
    localStorage.removeItem("userType");
  }
}
