const buildPath = (...elements: string[]) => `/${elements.join("/")}`;

const SCOPES = {
  accounts: "accounts",
  workers: "workers",
  employers: "employers",
  globals: "globals",
  workerJobPosition: "worker_job_positions",
  form: "form",
  organizations: "organizations",
};

type GenericEndpoint = { [key: string]: string };

interface EndpointType {
  workers: GenericEndpoint;
  workerJobPositions: GenericEndpoint;
  employers: GenericEndpoint;
  accounts: GenericEndpoint;
  globals: GenericEndpoint;
  organizations: GenericEndpoint;
}

export const ENDPOINTS: EndpointType = {
  workers: {
    root: SCOPES.workers,
    rootSlashed: buildPath(SCOPES.workers, ""),
    me: buildPath(SCOPES.workers, "me"),
    form: buildPath(SCOPES.form, "show_edit_worker_form"),
    boostStatus: buildPath(SCOPES.workers, "subscription_status"),
    researchStatus: buildPath(SCOPES.workers, "check_research_status"),
    applicationStatus: buildPath(SCOPES.workers, "check_application_status"),
    asset: buildPath(SCOPES.workers, "asset"),
    minifyImage: buildPath(SCOPES.workers, "minify_image"),
    nativeCV: buildPath(SCOPES.workers, "native_cv", ""),
    uploadCV: buildPath(SCOPES.workers, "upload_cv"),
    emailValidity: buildPath(SCOPES.workers, "check_validity"),
    sendPicture: buildPath(SCOPES.workers, "picture"),
    scout: buildPath(SCOPES.workers, "scout"),
  },
  workerJobPositions: {
    root: SCOPES.workers,
    rootSlashed: buildPath(SCOPES.workers, ""),
    jobPosition: buildPath(SCOPES.workerJobPosition, "job_positions"),
    jobPositionApply: buildPath(SCOPES.workerJobPosition, "apply"),
    jobApplication: buildPath(SCOPES.workerJobPosition, "job_application"),
    spontaneousApplications: buildPath(SCOPES.workerJobPosition, "spontaneous_applications"),
    spontaneousApplication: buildPath(SCOPES.workerJobPosition, "spontaneous_application"),
  },
  employers: {
    root: SCOPES.employers,
    rootSlashed: buildPath(SCOPES.employers, ""),
    restaurants: buildPath(SCOPES.employers, "restaurants"),
    jobPositions: buildPath(SCOPES.employers, "job_positions"),
    referralJobPositions: buildPath(SCOPES.employers, "referral_job_positions"),
    jobPositionActive: buildPath(SCOPES.employers, "job_positions", "active", ""),
    specificJobPosition: buildPath(SCOPES.employers, "job_positions", ""),
    jobApllication: buildPath(SCOPES.workers, "job_application"),
    JPWorker: buildPath(SCOPES.employers, "job_positions", "workers"),
    stripeCustomerPortal: buildPath(SCOPES.employers, "stripe_customer_portal"),
    jobPositionViewLog: buildPath(SCOPES.employers, "job_positions", "log_view"),
    createCheckoutSession: buildPath(SCOPES.employers, "create_checkout_session"),
    subscriptionSession: buildPath(SCOPES.employers, "subscription_session"),
  },
  accounts: {
    root: SCOPES.accounts,
    rootSlashed: buildPath(SCOPES.accounts, ""),
    login: buildPath(SCOPES.accounts, "login"),
    signUp: buildPath(SCOPES.accounts, "sign_up"),
    formId: buildPath(SCOPES.form, ""),
    formSessionId: buildPath(SCOPES.form, "form_session"),
    forgotPassword: buildPath(SCOPES.accounts, "forgot_password"),
    resetPassword: buildPath(SCOPES.accounts, "reset_password"),
    passwordlessLogin: buildPath(SCOPES.accounts, "get_passwordless_login"),
    employerUserContact: buildPath(SCOPES.accounts, "employer_user_contact"),
  },
  globals: {
    root: SCOPES.globals,
    rootSlashed: buildPath(SCOPES.globals, ""),
    variables: buildPath(SCOPES.globals, "variables", ""),
  },
  organizations: {
    root: SCOPES.organizations,
  },
};
