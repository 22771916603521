import { useMemo } from "react";
import { EditFormPayload, FormPayload } from "types";
import { Service } from "./baseService";
import { ENDPOINTS } from "~/constants/apiEndPoints";
import useAuth from "~/hooks/useAuth";
import dynamicAxios from "~/utils/axios";
import { Asset } from "~/pages/worker/edit_old";
import { encode } from "~/utils/common";

type jobParams = {
  country?: string;
  locality?: string;
  seasonal_accommodation?: boolean;
  domain_interest?: string;
};

class ServiceWorker extends Service {
  fetchWorkerData() {
    return dynamicAxios(this.jwt).get(ENDPOINTS.workers.me);
  }

  fetchWorkerByReferralCode(referralCode: string) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.workers.scout, { params: { referral_code: referralCode } });
  }

  fetchWorkerAsset(url: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workers.asset}`, {
      params: { url },
      responseType: "blob",
    });
  }

  fetchResearchStatus() {
    return dynamicAxios(this.jwt).get(ENDPOINTS.workers.researchStatus);
  }

  fetchBoostStatus(id: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workers.boostStatus}`, {
      params: {
        id,
      },
    });
  }

  fetchApplicationStatus(worker_id: string) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.workers.applicationStatus, {
      params: {
        worker_id,
      },
    });
  }

  workerForm() {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workers.form}`);
  }

  fetchJobPositions(limit: number, offset: number, status_key: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workerJobPositions.jobPosition}`, {
      params: {
        limit,
        offset,
        status_key,
      },
    });
  }

  fetchOffers(limit: number, offset: number, sentiment: string) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workerJobPositions.jobPosition}`, {
      params: {
        limit,
        offset,
        sentiment,
      },
    });
  }

  indexJobPositions(offset: number, limit: number, params: jobParams) {
    const { country, locality, seasonal_accommodation, domain_interest } = params;

    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.JPWorker, {
      params: {
        offset,
        limit,
        country,
        locality,
        seasonal_accommodation,
        domain_interest,
      },
    });
  }

  fetchNativeCV(username: string, collect_metrics: boolean) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.workers.nativeCV}${username}`, {
      params: {
        collect_metrics,
      },
    });
  }

  checkEmailValidity(email: string) {
    return dynamicAxios().get(ENDPOINTS.workers.emailValidity, {
      params: {
        email,
      },
    });
  }

  updateEmail(data: object) {
    return dynamicAxios(this.jwt).patch(`${ENDPOINTS.accounts.root}`, data);
  }

  apply(data: object) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workerJobPositions.jobPositionApply, data);
  }

  publicApply(data: object) {
    return dynamicAxios().post(ENDPOINTS.workerJobPositions.jobPositionApply, data);
  }

  publicUpdateJobApplication(workerId: string, jobPositionId: string, statusKey: string) {
    return dynamicAxios().patch(ENDPOINTS.workerJobPositions.jobApplication, {
      worker_id: workerId,
      job_position_id: jobPositionId,
      status_key: statusKey,
      user_type: "worker",
    });
  }

  updateWorker(data: EditFormPayload | null, id?: string) {
    return dynamicAxios(this.jwt).patch(`${ENDPOINTS.workers.rootSlashed}${id}`, data);
  }

  minifyImage(data: object) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workers.minifyImage, data, { responseType: "blob" });
  }

  sendAsset(data: object) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workers.asset, data);
  }

  upsertWorker(payload: object) {
    return dynamicAxios().post(ENDPOINTS.workers.root, payload);
  }

  sendCV(data: object) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.workers.uploadCV, data);
  }

  sendWorkerData(data: FormPayload | null, session_id: string, step_id: number) {
    return dynamicAxios().post(ENDPOINTS.workers.root, data, {
      params: {
        session_id,
        step_id,
      },
    });
  }

  sendPicture(data: FormData) {
    return dynamicAxios().post(ENDPOINTS.workers.sendPicture, data);
  }

  deleteAsset(asset: Asset) {
    return dynamicAxios(this.jwt).delete(`${ENDPOINTS.workers.asset}/${encode(asset.url ?? "")}`);
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceWorker(jwt), [jwt]);
};

export default useService;
