import axios from "axios";

// ----------------------------------------------------------------------

const formatError = (error: any) => ({
  code: error.response.status,
  message: error.message,
  response: error.response,
});

const dynamicAxios = (token?: string | null) => {
  const axiosInstance = axios.create();

  axiosInstance.defaults.baseURL = `${process.env.NEXT_PUBLIC_API_URL}/`;

  axiosInstance.interceptors.request.use((config) => {
    const authToken = token || localStorage.getItem("accessToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(formatError(error) || "Something went wrong")
  );
  return axiosInstance;
};

export default dynamicAxios;
